// NOTE: Dialog is being used from Ascend though the other icons and Dialog related components are
// coming directly from MUI. TODO - ask Ascend team to see if this can all come from them
import { Button, Typography, IconButton } from '@achieve/ascend'
import { Dialog } from '@mui/material'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import CloseIcon from '@mui/icons-material/Close'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'

import { useState, useReducer, useEffect } from 'react'
import { VideoPlayer } from 'components/VideoPlayer'
import useViewportSize from 'hooks/useViewportSize'
import { videoMap } from 'constants/videoMappings'
import { useInlineMemoizedContentGetter } from 'utils/contentful'
import styles from './VideoDialog.module.scss'
import { useViewportSmallerThan } from 'utils/viewport'
import { BREAKPOINTS } from 'constants/viewport'

import { useTriggerEvents } from 'hooks/useTriggerEvents'
import { PlayButtonBackground } from 'components/PlayButtonBackground'

const reducerFunction = (state, action) => {
  switch (action.type) {
    case 'calculatedClient':
      return { ...state, ...action.payload }
    default:
      return state
  }
}

function VideoDialog({ content, trackOpen = {}, backgroundCta = false }) {
  const {
    title,
    videoPlayer,
    altText,
    source,
    buttonCta = true,
  } = useInlineMemoizedContentGetter(content, [
    'title',
    'videoPlayer',
    'altText',
    'source',
    'buttonCta',
  ])
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  const [open, setOpen] = useState(false)

  const { breakpointLetterSize, calculatedClient } = useViewportSize()
  const [state, dispatch] = useReducer(reducerFunction, {
    width: videoMap[breakpointLetterSize].width,
  })

  // Retrieving AnalyticsContext for dispatching events
  const { fireAndForget } = useTriggerEvents()

  useEffect(() => {
    if (calculatedClient) {
      dispatch({
        type: 'calculatedClient',
        payload: {
          width: videoMap[breakpointLetterSize].width,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculatedClient, breakpointLetterSize])

  const handleClickOpen = () => {
    fireAndForget({
      ...trackOpen,
      list_name: 'Video Dialog CTA Button Click',
      click_id: 'Watch the story',
      click_type: 'Button Click',
      click_text: title,
      click_url: source,
      track_event: 'button_click',
      event_action: 'button_click',
      event_type: 'button_click',
    })
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      {backgroundCta ? (
        <PlayButtonBackground onClick={handleClickOpen} />
      ) : (
        <Button
          data-testid="video-dialog-button"
          data-video-dialog-open={true}
          color="primary"
          variant={buttonCta || buttonCta === null ? 'contained' : 'text'}
          onClick={handleClickOpen}
          className={styles['button']}
          endIcon={<PlayCircleOutlineIcon data-testid="circle-icon" />}
        >
          <Typography variant="bodyLg" fontWeight="bold">
            {title}
          </Typography>
        </Button>
      )}
      <Dialog
        data-testid="video-dialog"
        className={styles['dialog-container']}
        fullWidth={false}
        maxWidth={false}
        fullScreen={isMobile}
        open={open}
        PaperProps={{
          sx: {
            width: isMobile ? 'max-content' : state.width,
            height: 'max-content',
            backgroundColor: 'transparent',
          },
        }}
      >
        <DialogContent data-testid="dialog-content" className={styles['dialog-content']}>
          <DialogActions data-testid="dialog-action" style={{ padding: 0, margin: 0 }}>
            <IconButton
              data-testid="icon-button"
              className={styles['icon-button']}
              onClick={handleClose}
              data-video-dialog-close={true}
            >
              <CloseIcon data-video-dialog-close={true} />
            </IconButton>
          </DialogActions>
          <div className={styles['video-player-container']}>
            <VideoPlayer
              trackOnPlay={{
                nav_link_section: 'Testimonials',
              }}
              content={
                !source
                  ? { ...videoPlayer, autoplay: true, openFullScreen: true }
                  : { altText, source, autoplay: true, openFullScreen: true }
              }
              maxWidth="true"
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export { VideoDialog }
