import { useState, useEffect, useRef } from 'react'

/**
 * Custom React hook that checks if an element is in the viewport.
 *
 * @param {Object} options - The options for the Intersection Observer.
 * @returns {Array} An array where the first item is the ref to attach to the element you want to observe, and the second item is a boolean that is true if the element is in the viewport.
 */

const DEFAULT_OPTION = { rootMargin: '100px', once: true }

function useOnScreen(options = DEFAULT_OPTION) {
  // State to hold whether the element is in the viewport.
  const [isIntersecting, setIntersecting] = useState(false)
  // Ref to the element we want to observe.
  const ref = useRef()

  useEffect(() => {
    let currentRef = null

    // Create a new Intersection Observer
    //Enable logging to debug, keep commented otherwise.
    const log = false
    const observer = new IntersectionObserver((entries) => {
      if (log) console.log('Created a new Intersection Observer', observer)
      entries.forEach((entry) => {
        // If the entry is the current ref, update the state and stop observing if it's in the viewport.
        if (entry.target === currentRef) {
          setIntersecting(entry.isIntersecting)
          if (entry.isIntersecting && options.once) {
            observer.unobserve(entry.target)
            if (log)
              console.log('Stopped observing an element after entry is intersected', currentRef)
          }
        }
      })
    }, options)

    // If the ref is attached to an element, start observing that element.
    if (ref.current) {
      currentRef = ref.current
      observer.observe(currentRef)
    }

    // Cleanup function to stop observing the current element on component unmount
    return () => {
      if (currentRef) {
        observer.unobserve(currentRef)
        if (log) console.log('Stopped observing an element in cleanup function', currentRef)
      }
    }
  }, [options])

  // Return the ref and the state to the caller for use
  return { ref, isIntersecting }
}

export default useOnScreen
