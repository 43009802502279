import { Card, CardContent, CardMedia as AscendCardMedia, CardActions } from '@achieve/ascend'
import { LinkButton, MediaImageStandard, Typography } from 'components/Contentful'
import styles from './CardMedia.module.css'

export function CardMedia({ content, color = 'primary', linkVariant = 'outlined' }) {
  /**
   * This is most likely temporary until a Ascend component is ready
   */
  const { cardTitleText, cardText, cardImage, cardLink } = content?.fields || {}
  return (
    <Card className={styles['media-card-container']}>
      {cardImage && (
        <AscendCardMedia>
          <MediaImageStandard content={cardImage} className={styles['media-card-image']} />
        </AscendCardMedia>
      )}
      <CardContent>
        {cardTitleText && <Typography content={cardTitleText} />}
        {cardText && <Typography content={cardText} />}
      </CardContent>
      {cardLink && (
        <CardActions>
          <LinkButton
            track={{
              ...{
                list_name: 'Card Media Event',
                click_id: 'card_media',
                click_position: '0',
                nav_link_section: 'Card Media',
                track_event: 'internal_campaign_click',
                event_action: 'link_click',
                event_type: 'link_click',
              },
              ...cardLink?.fields?.event,
            }}
            content={cardLink.fields}
            color={color}
            variant={linkVariant}
            underline="hover"
          />
        </CardActions>
      )}
    </Card>
  )
}
